// Dependency
import React from "react";
import { Link } from "gatsby";

// Components
import Layout from "../components/layouts";

// Services
import UtilsService from "../services/utils.service";

// Internationalization
import { useTranslation } from 'react-i18next';

// Assets
import breakImg from '../images/404.svg';


const NotFound = ({pageContext}) => {
    // Translation Function
    const { t } = useTranslation();

    return(
        <Layout country={UtilsService.getLocaleFromUrl().country}>
            <div className="cd-404-page">
                <div className="cd-max-width">
                    <div className="cd-404-wrapper">
                        <div className="cd-404-content">
                            <div className="cd-404-info">
                                <h1>404</h1>
                            </div>
                            <Link className="button button--light button--outlined" to={`/${UtilsService.getLocaleFromUrl().country}/shop/`}>{t('miniCart.continueShopping')}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default NotFound